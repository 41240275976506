import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Landing from '../Landing'
import Signin from '../Signin'
import Signout from '../Signout'
import Legal from '../Legal'
import Contact from '../Contact'
import NotFound from '../404'
// import Contact from '../Contact'

export default class App extends Component {
  componentDidMount() {
    document.title = `Radio Musique Test - Leader français des sondages musicaux en ligne`
    AOS.init({
      once: true, // whether animation should happen only once - while scrolling down
    })
  }
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route path="/signin">
              <Signin />
            </Route>
            <Route path="/signout">
              <Signout />
            </Route>
            <Route path="/rules">
              <Legal doc="rules" />
            </Route>
            <Route path="/privacy-policy">
              <Legal doc="privacy-policy" />
            </Route>
            <Route path="/legal">
              <Legal doc="mentions" />
            </Route>{' '}
            <Route path="/terms">
              <Legal doc="terms" />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}
