import React, { Component } from 'react'
import './Winner.scss'

export default class Winner extends Component {
  render() {
    return (
      <div className="feed__winner">
        <span className="feed__winner__date">{this.props.date}</span>
        <span className="feed__winner__post">{this.props.children}</span>
      </div>
    )
  }
}
