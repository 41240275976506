import React, { Component } from 'react'
import Home from './Home'
import Participation from './Participation'
import Gains from './Gains'
import Studies from './Studies'

export default class Landing extends Component {
  render() {
    return (
      <div>
        <Home />
        <Participation />
        <Gains />
        <Studies />
      </div>
    )
  }
}
