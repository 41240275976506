import React, { Component } from 'react'
import Linkify from 'react-linkify'
import './Study.scss'

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" without rel="noopener noreferrer">
    {text}
  </a>
)

export default class Study extends Component {
  render() {
    let media
    if (this.props.mediaType === 'image') {
      media = (
        <img
          className="studies_column_study__media"
          src={this.props.mediaLink}
          alt=""
        />
      )
    }
    if (this.props.mediaType === 'video') {
      media = (
        <video controls className="studies_column_study__media">
          <source src={this.props.mediaLink} />
        </video>
      )
    }

    return (
      <div className="studies_column_study" data-aos="fade-in">
        <div className="studies_column_study__post">
          <p>
            <Linkify componentDecorator={componentDecorator}>
              {this.props.children}
            </Linkify>
          </p>
        </div>
        <span className="studies_column_study__date">{this.props.date}</span>
        {media}
        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
          <span className="studies_column_study__link">Participer</span>
        </a>
      </div>
    )
  }
}
