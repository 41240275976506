import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import './Footer.scss'
import Logo from './logo_grayscale.png'

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="grid-container">
          <div className="col-4">
            <img className="logo" alt="logo" src={Logo} />
          </div>
          <div className="col-4">
            <nav>
              <h3>Radio Musique Test</h3>
              <Link to="/signin">
                <span className="nav__item">Inscription</span>
              </Link>
              <HashLink smooth to="/#about">
                <span className="nav__item">Participation</span>
              </HashLink>
              <HashLink smooth to="/#gains">
                <span className="nav__item">Gains</span>
              </HashLink>
              <HashLink smooth to="/#studies">
                <span className="nav__item">Sondages</span>
              </HashLink>
            </nav>
          </div>
          <div className="col-4">
            <nav>
              <h3>Ressources</h3>
              <Link to="/signout">
                <span className="nav__item">Désinscription</span>
              </Link>
              <Link to="/privacy-policy">
                <span className="nav__item">Politique de confidentialité</span>
              </Link>
              <Link to="/terms">
                <span className="nav__item">
                  Conditions générales d'utilisation
                </span>
              </Link>{' '}
              <Link to="/legal">
                <span className="nav__item">Mentions légales</span>
              </Link>
            </nav>
          </div>
        </div>
        <div className="legal">
          Copyright © {new Date().getFullYear()} RadioMusiqueTest - HyperWorld
        </div>
      </footer>
    )
  }
}
