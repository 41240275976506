import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as moment from 'moment'
import 'moment/locale/fr'

import Winner from './Winner'
import './Gains.scss'
import Button from '../../Button'

export default class Gains extends Component {
  constructor(props) {
    super(props)
    this.state = {
      winners: null,
    }
  }

  componentDidMount() {
    fetch('/fb_posts.json')
      .then(response => response.json())
      .then(data => {
        const filteredPosts = data.filter(
          post => post.message.indexOf('[GAGNANTS]') !== -1,
        )
        this.setState({
          winners: filteredPosts,
        })
        console.log(filteredPosts)
      })
  }

  render() {
    return (
      <section id="gains">
        <div className="grid-container">
          <div className="spacer col-3"></div>
          <h2 className="col-9">votre opinion est précieuse</h2>
          <div className="spacer col-3"></div>
          <div className="col-5 content">
            <p>
              Chez Radio Musique Test, nous attachons une grande valeur aux
              opinions que vous partagez avec nous ainsi qu’au temps que vous
              consacrez à répondre à nos sondages.
            </p>
            <p>
              C'est pourquoi, lorsque vous participez à un sondage, de nombreux
              lots sont à gagner !
            </p>
            <p>
              Retrouvez ci-contre la liste des gagnants de nos derniers
              sondages.
            </p>
            <div className="btnGroup">
              <Link to="/signin">
                <Button>Je m'inscris</Button>
              </Link>
              <a
                href="https://www.facebook.com/RadioMusiqueTest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="fb" icon="facebook">
                  Nous suivre
                </Button>
              </a>
            </div>
          </div>
          {this.state.winners && (
            <div className="col-4 feed">
              <div className="feed__wrapper">
                {this.state.winners.map((winner, i) => {
                  return (
                    <Winner
                      key={i}
                      date={moment(winner.created_time)
                        .locale('fr')
                        .format('LL')}
                    >
                      {winner.message}
                    </Winner>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <div className="fluid_bg">
          <div className="fluid_bg__girl" />
        </div>
      </section>
    )
  }
}
