import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import './Legal.scss'
// import Rules from './rules.md'
import Privacy from './privacy.md'
import Mentions from './mentions.md'
import Terms from './terms.md'

const docMap = {
  // rules: Rules,
  'privacy-policy': Privacy,
  mentions: Mentions,
  terms: Terms,
}
const docTitle = {
  // rules: 'Règlements',
  'privacy-policy': 'Politique de confidentialité',
  mentions: 'Mentions légales',
  terms: "Conditions générales d'utilisations",
}

export default class Legal extends Component {
  state = { content: 'Chargement' }
  componentDidMount() {
    document.title = `Radio Musique Test - ${docTitle[this.props.doc]}`
    fetch(docMap[this.props.doc])
      .then(response => response.text())
      .then(content => this.setState({ content }))
  }
  componentDidUpdate(prevProps) {
    if (this.props.doc !== prevProps.doc) {
      document.title = `Radio Musique Test - ${docTitle[this.props.doc]}`
      fetch(docMap[this.props.doc])
        .then(response => response.text())
        .then(content => this.setState({ content }))
    }
    window.scrollTo(0, 1)
  }

  render() {
    const { content } = this.state
    return (
      <section id="legal">
        <div className="grid-container">
          <div className="col-12">
            <ReactMarkdown source={content} />
          </div>
        </div>
      </section>
    )
  }
}
