import PropTypes from 'prop-types'
import React from 'react'
import { composeHandlers } from 'react-behave'
import classNames from 'classnames'
import './Selector.scss'

const TYPES = ['checkbox', 'radio']

export default function Selector({
  type,
  children,
  // Forwarded props.
  checked,
  disabled,
  className,
  style,
  onBlur,
  onFocus,
  refProp,
  ...props
}) {
  const [focus, setFocus] = React.useState(false)

  const selectorClassName = classNames('Selector', className, {
    'Selector--focus': focus,
    'Selector--checked': checked,
    'Selector--disabled': disabled,
  })

  const markClassName = classNames('Selector__mark', {
    'Selector__mark--checked': checked,
  })

  const markIconClassName = classNames(
    'Selector__mark__icon',
    `Selector__mark__icon--${type}`,
    { 'Selector__mark__icon--checked': checked },
  )

  const iconName =
    type === 'checkbox' ? 'tick' : checked ? 'radio-on' : 'radio-off'

  return (
    <label className={selectorClassName} style={style}>
      <input
        ref={refProp}
        type={type}
        checked={checked}
        disabled={disabled}
        onFocus={composeHandlers([() => setFocus(true), onFocus])}
        onBlur={composeHandlers([() => setFocus(false)], onBlur)}
        className="Selector__nativeInput"
        {...props}
      />

      <span className={markClassName}>
        <i className={`${markIconClassName} icon icon-${iconName}`} />
      </span>

      <span className="Selector__label">{children}</span>
    </label>
  )
}

Selector.propTypes = {
  /**
   * The content of the selector.
   */
  children: PropTypes.node.isRequired,

  /**
   * The type of the selector.
   */
  type: PropTypes.oneOf(TYPES).isRequired,

  /**
   * The adornment of the component.
   * It can either be an `Icon` or `Asset`.
   */
  adornment: PropTypes.element,
}

const SelectorRef = React.forwardRef((props, ref) => (
  <Selector {...props} refProp={ref} />
))

SelectorRef.TYPES = TYPES

export { SelectorRef as Selector }
