import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Home.scss'
import Button from '../../Button'

export default class Home extends Component {
  render() {
    return (
      <section id="home">
        <div className="grid-container">
          <div className="main">
            <h2 className="title">
              Leader français des <em>sondages musicaux</em> en ligne
            </h2>
            <h3 className="abstract">
              Depuis plus de 10 ans, Radio Musique Test amplifie vos goûts
              musicaux auprès des professionnels de la musique et des médias.
              <br />
              En participant à nos sondages musicaux en ligne, vous donnez un
              nouvel écho à votre avis et aidez directement vos médias favoris à
              mieux vous comprendre.
            </h3>
            <div className="btnGroup">
              <Link to="/signin">
                <Button>Je m'inscris</Button>
              </Link>
              <a
                href="https://www.facebook.com/RadioMusiqueTest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="fb" icon="facebook">
                  Nous suivre
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div className="mice">
          <div className="scroll"></div>
        </div>
      </section>
    )
  }
}
