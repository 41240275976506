import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Participation.scss'
import Button from '../../Button'
import {
  Europe1,
  Skyrock,
  Rfm,
  Fgdj,
  Tracefm,
  Chantefrance,
  NrjAntilles,
  Tropiquefm,
  M6pub,
  Mouv,
  Francebleu,
  Franceinter,
  Latina,
  Ouifm,
  Champagnefm,
  Voltage,
  Onefm,
  Lfm,
  Radiolac,
  Bnj,
  Lapremiere,
  Classic21,
  Vivacite,
  Tarmac,
} from './logos/'

const Logos = [
  Europe1,
  Skyrock,
  Rfm,
  Fgdj,
  Tracefm,
  Chantefrance,
  NrjAntilles,
  Tropiquefm,
  M6pub,
  Mouv,
  Francebleu,
  Franceinter,
  Latina,
  Ouifm,
  Champagnefm,
  Voltage,
  Onefm,
  Lfm,
  Radiolac,
  Bnj,
  Lapremiere,
  Classic21,
  Vivacite,
  Tarmac,
]

function getAlt(path) {
  let temp = path.split('/')
  let alt = temp[temp.length - 1].split('.')[0]
  return alt
}

export default class Participation extends Component {
  render() {
    return (
      <section id="about">
        <div className="grid-container">
          <h2 className="col-12 title">Faites entendre votre voix</h2>
          <div className="col-5 content">
            <p>
              En rejoignant gratuitement Radio Musique Test vous avez le pouvoir
              d'influencer la musique de demain. Comment ? C’est très simple !
            </p>
            <p>
              A chaque fois que vous décidez de participer à l’un de nos
              sondages récompensés, vous devenez acteur du changement en aidant
              les professionnels de la musique et des médias à comprendre vos
              attentes et ainsi mieux vous satisfaire. Une fois votre
              inscription enregistrée vous serez régulièrement sollicité afin de
              donner votre avis sur différents extraits musicaux. Bien entendu,
              à chaque sondage vous êtes totalement libre de participer ou non.
            </p>
            <p>
              Et après ? Consultez régulièrement la page Facebook Radio Musique
              Test pour vérifier si vous faites partie des heureux gagnants.
            </p>
            <div className="btnGroup">
              <Link to="/signin">
                <Button>Je m'inscris</Button>
              </Link>
              <a
                href="https://www.facebook.com/RadioMusiqueTest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="fb" icon="facebook">
                  Nous suivre
                </Button>
              </a>
            </div>
          </div>
          <div className="col-1 spacer" />
          <div className="col-4 logos" data-aos="fade-in">
            {Logos.map((logo, i) => {
              return (
                <img
                  key={i}
                  data-aos="fade-in"
                  data-aos-offset={i * 2}
                  className="logos__station"
                  src={logo}
                  alt={getAlt(logo)}
                />
              )
            })}
          </div>
          <div className="col-12"></div>
        </div>
      </section>
    )
  }
}
