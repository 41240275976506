import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import { Input, Select, Selector, Checkbox } from '../Form'
import makeAnimated from 'react-select/animated'
import SelectCreatable from 'react-select/creatable'
import './Signin.scss'
import * as moment from 'moment'

import Radios from '../../assets/signin/radios'

const animatedComponents = makeAnimated()

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export default class Signin extends Component {
  state = {
    gender: null,
    firstname: null,
    profession: null,
    birthday: null,
    city: null,
    zipCode: null,
    cleanedZipCode: null,
    email: null,
    listeningHabits: null,
    radioChoices: [],
    lastRadios: [],
    firstRadio: null,
    isFilled: null,
    secondRadio: null,
    errorBirthday: false,
    errorZipcode: false,
    errorProfession: false,
    errorListeningHabits: false,
    errorLastRadios: false,
    errorFirstRadio: false,
    errorSecondRadio: false,
    errorGender: false,
    error: false,
    checked: { first: false, second: false, third: false },
    fetchError: '',
    fetchSuccess: false,
    fetchDone: false,
  }

  careerChoices = [
    { value: 1, label: 'Agriculteur exploitant' },
    { value: 2, label: 'Artisan, commerçant' },
    { value: 3, label: "Chef d'entreprise" },
    { value: 4, label: 'Cadre et professions intellectuelle supérieures' },
    { value: 5, label: 'Elève - étudiant' },
    { value: 6, label: 'Employé' },
    { value: 7, label: 'Ouvrier' },
    {
      value: 8,
      label: 'Profession intermédiaire (enseignement, santé, travail social…)',
    },
    { value: 9, label: 'Retraité' },
    { value: 10, label: "En recherche active d'emploi" },
    { value: 11, label: 'Autre (bénévolat, travail au sein du foyer…)' },
  ]

  listeningHabits = [
    { value: 1, label: 'Tous les jours' },
    { value: 2, label: 'Presque tous les jours' },
    { value: 3, label: '1 à 2 fois par semaine' },
    { value: 4, label: 'Moins souvent' },
    { value: 5, label: 'Jamais' },
  ]

  handleGenderChange = gender => () => {
    this.setState({ gender, errorGender: false })
  }

  handleZipCodeChange = evt => {
    let zipCode = evt.target.value
    const cleaned = parseInt(zipCode.slice(0, 2))
    this.setState({
      zipCode,
      cleanedZipCode: zipCode.length === 2 ? `${zipCode}000` : zipCode,
      radioChoices: ([...Radios[cleaned], 'Autre'] || ['Autre']).map(
        (radioName, index) => {
          return { value: index, label: radioName }
        },
      ),
      errorZipcode: zipCode.length !== 2 && zipCode.length !== 5,
    })
  }

  // TODO: Pour le moment je parse les /, surement à changer après le passage de Brice
  handleBirthdayChange = evt => {
    let error = false
    const parsed = moment(evt.target.value)
    if (!parsed.isValid()) error = 'Date de naissance invalide'
    else {
      const yearDiffFromNow = moment
        .duration(moment(new Date()).diff(parsed))
        .asYears()
      if (yearDiffFromNow < 16)
        error =
          "Vous n'avez pas l'age requis pour vous inscrire (minimum 16 ans)"
      else if (yearDiffFromNow > 100) error = 'Date de naissance invalide'
    }
    this.setState({
      birthday: evt.target.value,
      errorBirthday: error,
    })
  }

  handleTextInputChange = inputName => evt => {
    this.setState({ [inputName]: evt.target.value })
  }

  handleSelectInputChange = inputName => evt => {
    console.log(evt)
    const errorName = `error${capitalize(inputName)}`
    this.setState({ [inputName]: evt.value, [errorName]: false })
  }

  handleRadioSelection = (selected, newOpt) => {
    this.setState({
      lastRadios: selected.map((val, index) => {
        return { value: index, label: val.label }
      }),
      // Need support, I guess this implem is shitty
      isFilled: selected,
      firstRadio: selected.length === 1 ? 0 : null,
    })
  }

  verifyForm = evt => {
    let error = false
    let newState = {}
    for (const empty of [
      'profession',
      'listeningHabits',
      'lastRadios',
      'firstRadio',
      'gender',
    ]) {
      if (
        // Maybe the value can be coalesced to a false (for instance 0 or "false")
        this.state[empty] === undefined ||
        this.state[empty] === null ||
        (this.state[empty] instanceof Array && !this.state[empty].length)
      ) {
        error = true
        const errorStateName = `error${capitalize(empty)}`
        console.log('Changing state of', empty, errorStateName)
        newState[errorStateName] = true
        // this.setState({ [stateName]: true })
      }
    }
    newState['error'] = error
    this.setState(newState)
    return error
  }

  onCheck = type => evt => {
    this.setState({
      checked: { ...this.state.checked, [type]: !this.state.checked[type] },
    })
  }

  componentDidMount() {
    document.title = `Radio Musique Test - Inscription`
    window.scrollTo(0, 0)
  }

  handleSubmit = event => {
    event.preventDefault()
    console.log('Submitting', this.state)

    const body = {
      gender: this.state.gender.toUpperCase(),
      name: this.state.firstname
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' '),
      profession: this.careerChoices.find(
        val => val.value === this.state.profession,
      ).label,
      birthday: this.state.birthday,
      city: this.state.city.toLowerCase(),
      zipCode: this.state.cleanedZipCode,
      email: this.state.email.toLowerCase().replace(/\s/g, ''),
      listeningHabits: this.listeningHabits.find(
        val => val.value === this.state.listeningHabits,
      ).label,
      radiosInLast7Days: this.state.lastRadios.map(val => val.label),
      firstFavorite: this.state.lastRadios.find(
        val => val.value === this.state.firstRadio,
      ).label,
      secondFavorite: !this.state.secondRadio
        ? ''
        : this.state.lastRadios.find(
            val => val.value === this.state.secondRadio,
          ).label,
    }
    console.log('BODY', body)

    // FIXME ?
    // Should we force a format for standard value
    //  - Capitalize name ? (First letter of each word)
    //  - all city in full lowercase (space stripped ?)
    //  - all zipcode should be of a certain length (complete with 0 when < length)
    //  - all emails in lowercase, space stripped
    //  - is second radio mandatory ?
    fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(async val => {
        console.log(val)
        const body = await val.json()
        console.log(body)
        if (val.status !== 200) {
          console.log('Got an error', val)
          let errorMessage = 'Une erreur inconnue est survenue'

          if (body.errorType === 'DuplicateUserError') {
            errorMessage = 'Vous avez déjà un compte chez Radio musique test'
          }
          this.setState({
            fetchDone: true,
            fetchSuccess: false,
            fetchError: errorMessage,
          })
        } else {
          this.setState({ fetchDone: true, fetchSuccess: true, fetchError: '' })
        }
      })
      .catch(err => {
        this.setState({ fetchDone: true, fetchError: err.message })
      })
  }

  render() {
    // Add common radios to all radios
    // const fullRadio = {}
    // Object.keys(Radios).map(key => {
    //   fullRadio[key] = Array.from(
    //     new Set(
    //       Radios[key]
    //         .concat([
    //           'EUROPE 1',
    //           'FRANCE BLEU',
    //           'FRANCE INTER',
    //           'RMC',
    //           'RTL',
    //           'CHERIE FM',
    //           'FUN RADIO',
    //           'MOUV',
    //           'M RADIO',
    //           'NOSTALGIE',
    //           'NRJ',
    //           'RADIO NOVA',
    //           'RFM',
    //           'RIRE ET CHANSONS',
    //           'RTL2',
    //           'SKYROCK',
    //           'VIRGIN RADIO',
    //           'FRANCE INFO',
    //           'FRANCE MUSIQUE',
    //           'FRANCE CULTURE',
    //           'RADIO CLASSIQUE',
    //           'FIP',
    //         ])
    //         .sort(),
    //     ),
    //   )
    // })
    // console.log(JSON.stringify(fullRadio))

    return (
      <div>
        {!this.state.fetchDone && (
          <section id="signin">
            <form onSubmit={this.handleSubmit}>
              <div className="grid-container">
                <div className="col-12">
                  <h2>Votre avis compte !</h2>
                  <p>
                    Afin de vous proposer de participer aux sondages musicaux
                    les plus pertinents, il est important pour Radio Musique
                    Test de mieux vous connaître. Bien entendu, les informations
                    communiquées resteront entièrement confidentielles et ne
                    feront en aucun cas l’objet d’une cession à des sociétés
                    tierces pour de la prospection commerciale.
                  </p>
                </div>
                <div className="col-6 userInfo">
                  <h3>Mes informations</h3>
                  <div className="userInfo__gender">
                    <div>
                      <Selector
                        checked={this.state.gender === 'male'}
                        type="radio"
                        onChange={this.handleGenderChange('male')}
                      >
                        Homme
                      </Selector>
                      <Selector
                        checked={this.state.gender === 'female'}
                        type="radio"
                        onChange={this.handleGenderChange('female')}
                      >
                        Femme
                      </Selector>
                    </div>
                    {this.state.errorGender && (
                      <span className="userInfo__gender__error">
                        Merci de choisir un genre
                      </span>
                    )}
                  </div>
                  <Input
                    className="userInfo__default"
                    label="Prénom"
                    value={this.state.firstname}
                    onChange={this.handleTextInputChange('firstname')}
                    name="given-name"
                    autocomplete="given-name"
                    required
                  />
                  <Select
                    className="userInfo__default"
                    options={this.careerChoices}
                    onChange={this.handleSelectInputChange('profession')}
                    value={this.careerChoices.find(
                      val => val.value === this.state.profession,
                    )}
                    label={'Activité principale'}
                    error={
                      this.state.errorProfession &&
                      'Merci de choisir une profession'
                    }
                  />
                  <Input
                    error={this.state.errorBirthday}
                    className="userInfo__default"
                    label="Date de naissance"
                    type="date"
                    value={this.state.birthday}
                    onChange={this.handleBirthdayChange}
                    name="bday"
                    autocomplete="bday"
                    required
                  />
                  <div className="userInfo__location">
                    <Input
                      className="userInfo__location__city"
                      label="Ville"
                      value={this.state.city}
                      onChange={this.handleTextInputChange('city')}
                      required
                    />
                    <Input
                      error={this.state.errorZipcode && 'Code postal invalide'}
                      className="userInfo__location__cp"
                      label="CP"
                      type="number"
                      value={this.state.zipCode}
                      onChange={this.handleZipCodeChange}
                      name="postal-code"
                      autocomplete="postal-code"
                      required
                    />
                  </div>
                  <Input
                    className="userInfo__default"
                    label="Adresse email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleTextInputChange('email')}
                    name="email"
                    autocomplete="email"
                    required
                  />
                </div>
                <div
                  className={`col-6 radio ${
                    this.state.errorZipcode ||
                    !this.state.cleanedZipCode ||
                    this.state.cleanedZipCode.length !== 5
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <h3>Les radios que j’ecoute</h3>
                  <Select
                    className="userInfo__default"
                    options={this.listeningHabits}
                    onChange={this.handleSelectInputChange('listeningHabits')}
                    value={this.listeningHabits.find(
                      val => val.value === this.state.listeningHabits,
                    )}
                    label={"Habitudes d'écoute de la radio"}
                    error={
                      this.state.errorListeningHabits &&
                      "Merci de renseigner vos habitudes d'écoute"
                    }
                  />
                  <SelectCreatable
                    formatCreateLabel={userInput => `Ajouter ${userInput}`}
                    placeholder={
                      'Toutes les radios écoutées dans les 7 derniers jours'
                    }
                    className={`MultiSelect__container${
                      this.state.isFilled !== null ? '--has-values' : ''
                    }`}
                    classNamePrefix="MultiSelect"
                    components={animatedComponents}
                    options={this.state.radioChoices}
                    isMulti
                    noOptionsMessage={() => 'Aucun résultat'}
                    onChange={this.handleRadioSelection}
                    // error={
                    //   this.state.errorProfession &&
                    //   'Merci de choisir au moins une radio'
                    // }
                  />
                  <Select
                    className="userInfo__default"
                    options={this.state.lastRadios.filter(
                      ({ value }) => value !== this.state.secondRadio,
                    )}
                    onChange={this.handleSelectInputChange('firstRadio')}
                    value={this.state.lastRadios.find(
                      val => val.value === this.state.firstRadio,
                    )}
                    label={'Première radio favorite pour la musique'}
                    error={
                      this.state.errorFirstRadio &&
                      'Merci de choisir au moins une radio'
                    }
                  />
                  <Select
                    className="userInfo__default"
                    options={this.state.lastRadios.filter(
                      ({ value }) => value !== this.state.firstRadio,
                    )}
                    onChange={this.handleSelectInputChange('secondRadio')}
                    value={this.state.lastRadios.find(
                      val => val.value === this.state.secondRadio,
                    )}
                    label={'Seconde radio favorite pour la musique'}
                    error={
                      this.state.errorSecondRadio &&
                      'Merci de choisir au moins une radio'
                    }
                  />
                  <div className="terms">
                    <Checkbox
                      checked={this.state.checked.first}
                      onChange={() => {
                        this.onCheck('first')()
                      }}
                    />
                    <p
                      onClick={() => {
                        this.onCheck('first')()
                      }}
                    >
                      En cochant la case, je reconnais avoir lu et j’accepte les{' '}
                      <Link
                        to="/terms"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Conditions Générales d’Utilisation
                      </Link>{' '}
                      du Panel RadioMusiqueTest
                    </p>
                  </div>{' '}
                  <div className="terms">
                    <Checkbox
                      checked={this.state.checked.second}
                      onChange={() => {
                        this.onCheck('second')()
                      }}
                    />
                    <p
                      onClick={() => {
                        this.onCheck('second')()
                      }}
                    >
                      En cochant la case, j’accepte que mes informations
                      renseignées soient collectées pour la réalisation de
                      l’enquête
                    </p>
                  </div>{' '}
                  <div className="terms">
                    <Checkbox
                      checked={this.state.checked.third}
                      onChange={() => {
                        this.onCheck('third')()
                      }}
                    />
                    <p
                      onClick={() => {
                        this.onCheck('third')()
                      }}
                    >
                      En cochant la case, j’accepte que mes informations
                      renseignées soient collectées pour les besoins des tirages
                      et jeux-concours organisés par la Société
                    </p>
                  </div>
                </div>
                <div className="col-12 submit">
                  <Button
                    tag="submit"
                    disabled={
                      !this.state.checked.first ||
                      !this.state.checked.second ||
                      !this.state.checked.third
                    }
                    aos={true}
                    onClick={event => {
                      const error = this.verifyForm()
                      window.scrollTo(0, 0)
                      if (error) {
                        event.preventDefault()
                        return false
                      }
                    }}
                  >
                    Je valide mon inscription
                  </Button>
                </div>
              </div>
            </form>
          </section>
        )}
        {this.state.fetchDone && this.state.fetchSuccess && (
          <section id="confirm">
            <i className="icon icon-checkbox-checked" />
            <h2>Félicitations, ton inscription est validée</h2>
            <a href="/">
              <Button>Retour à l'accueil</Button>
            </a>
          </section>
        )}
        {this.state.fetchDone && this.state.fetchError && (
          <section id="failed">
            <i className="icon icon-close-round" />
            <h2>{this.state.fetchError}</h2>
            <a href="/signin">
              <Button>Retour au formulaire</Button>
            </a>
          </section>
        )}
      </div>
    )
  }
}
