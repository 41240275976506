import React, { Component } from 'react'
import Button from '../Button'
import { Input, Textarea } from '../Form'
import './Contact.scss'

export default class Contact extends Component {
  state = {
    status: '',
    firstname: '',
    email: '',
    message: '',
  }
  componentDidMount() {
    document.title = `Radio Musique Test - Contact`
    window.scrollTo(0, 0)
  }
  handleTextInputChange = inputName => evt => {
    this.setState({ [inputName]: evt.target.value })
  }
  handleSubmit = ev => {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: 'SUCCESS' })
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }
  render() {
    const { status } = this.state
    return (
      <section id="contact">
        <form
          onSubmit={this.handleSubmit}
          action="https://formspree.io/mwklnyko"
          method="POST"
        >
          <div className="grid-container">
            <div className="col-12">
              <h2>Contact</h2>
            </div>
            <div className="content col-6">
              <p>
                Vous avez des questions à nous poser ? Veuillez remplir le
                formulaire ci-contre et un membre de notre équipe vous répondra
                dans les meilleurs délais.
              </p>
              <p>
                Si votre question concerne un sondage spécifique, veuillez nous
                indiquer tous les informations nécessaires (numéro de sondage,
                lien inscrit sur l’email d’invitation, le sujet, le montant de
                la récompense et la date…) afin que nous puissions identifier le
                sondage et vous aider au plus vite.
              </p>
              <p> Musicalement vôtre. </p>
              <p>L'équipe Radio Musique Test</p>
            </div>
            <div className="form col-6">
              <Input
                className="form__default"
                label="Prénom"
                name="prenom"
                type="text"
                value={this.state.firstname}
                onChange={this.handleTextInputChange('firstname')}
              />
              <Input
                className="form__default"
                label="Adresse email"
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleTextInputChange('email')}
              />
              <Textarea
                className="form__default"
                label="Message"
                name="message"
                rows="15"
                value={this.state.message}
                onChange={this.handleTextInputChange('message')}
              />
              <div className="btnGroup">
                {status === 'SUCCESS' ? (
                  <p>
                    Merci, notre équipe vous répondra sous les meilleurs délais!
                  </p>
                ) : (
                  <Button type="submit" tag="submit">
                    Envoyer
                  </Button>
                )}
                {status === 'ERROR' && (
                  <p>
                    Une erreur s'est produite lors de l'envoi, vérifiez vos
                    informations
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>
    )
  }
}
