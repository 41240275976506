import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class WithClickOutside extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
      .isRequired,
  }

  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClick)
  }

  onClick = event => {
    if (this.ref && !this.ref.current.contains(event.target)) {
      this.props.onClick(event)
    }
  }

  render() {
    const { children } = this.props
    return <div ref={this.ref}>{children}</div>
  }
}
