import React, { Component } from 'react'
import Button from '../Button'
import { Input } from '../Form'
import './Signout.scss'

export default class Signin extends Component {
  state = {
    email: null,
    submitDone: false,
  }

  componentDidMount() {
    document.title = `Radio Musique Test - Desinscription`
    window.scrollTo(0, 0)
  }

  handleInputChange = event => {
    this.setState({ email: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()

    const body = {
      email: this.state.email,
    }

    // We do not care for the return (the server will always return 200 OK on this route)
    fetch('/api/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(async val => {
        this.setState({ submitDone: true })
      })
      .catch(err => {
        this.setState({ submitDone: true })
      })
  }

  render() {
    console.log(this.state)
    return !this.state.submitDone ? (
      <section id="signout">
        <form onSubmit={this.handleSubmit}>
          <div className="grid-container">
            <div className="col-12">
              {this.state.submitDone && (
                <div>
                  Votre demande a bien été prise en compte. Vous êtes supprimer
                  de notre base utilisateur.
                </div>
              )}
              <h2>Vous souhaitez nous quitter ?</h2>
              <div className="content">
                <p>
                  Rien n’est plus précieux que votre liberté. <br />
                  Vous pouvez donc à tout moment vous désinscrire de Radio
                  Musique Test en nous rappelant le mail utilisé lors de votre
                  inscription.
                </p>
                <Input
                  className="userEmail"
                  label="Votre adresse email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
                <div className="btnGroup">
                  <Button
                    variant="red"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Je supprime mon compte
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    ) : (
      <section id="confirm">
        <i className="icon icon-checkbox-checked" />
        <h2>Félicitations, votre désinscription a été prise en compte</h2>
        <a href="/">
          <Button>Retour à l'accueil</Button>
        </a>
      </section>
    )
  }
}
