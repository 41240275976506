export const Bnj = require('./bnj.png')
export const Chantefrance = require('./chantefrance.png')
export const Classic21 = require('./classic21.png')
export const Europe1 = require('./europe1.png')
export const Fgdj = require('./fgdj.png')
export const Francebleu = require('./francebleu.png')
export const Franceinter = require('./franceinter.png')
export const Lapremiere = require('./lapremiere.png')
export const Lfm = require('./lfm.png')
export const M6pub = require('./m6pub.png')
export const Mouv = require('./lemouv.png')
export const NrjAntilles = require('./nrjantilles.png')
export const Onefm = require('./onefm.png')
export const Radiolac = require('./radiolac.png')
export const Rfm = require('./rfm.png')
export const Skyrock = require('./skyrock.png')
export const Tarmac = require('./tarmac.png')
export const Tracefm = require('./tracefm.png')
export const Tropiquefm = require('./tropiquesfm.png')
export const Vivacite = require('./vivacite.png')
export const Latina = require('./latina.png')
export const Ouifm = require('./ouifm.png')
export const Champagnefm = require('./champagnefm.png')
export const Voltage = require('./voltage.png')
