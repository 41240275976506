import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './Button.scss'

export default class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(['primary', 'red', 'fb']),
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    className: PropTypes.string,
    aos: PropTypes.bool,
    type: PropTypes.string,
  }
  static defaultProps = {
    variant: 'primary',
    disabled: false,
    tag: 'span',
  }
  onClick = event => {
    const { disabled, onClick } = this.props
    if (disabled) return
    if (onClick) return onClick(event)
    return true
  }
  render() {
    const {
      variant,
      tag,
      disabled,
      icon,
      className,
      onClick,
      aos,
      ...props
    } = this.props
    const btnClass = classNames('btn', className, {
      [`btn--${variant}`]: variant && !disabled,
      [`btn--${variant} btn--${variant}-disabled`]: variant && disabled,
    })
    const Tag = tag
    const isInput = tag === 'submit'
    return !isInput ? (
      <div
        className={btnClass}
        onClick={this.onClick}
        {...(this.props.aos ? '' : { 'data-aos': 'fade-in' })}
        {...props}
      >
        <Fragment>
          {this.props.children && (
            <Tag className="btn__label">
              {icon && <i className={`btn__icon icon icon-${icon}`} />}
              {this.props.children}
            </Tag>
          )}
        </Fragment>
      </div>
    ) : (
      <input
        type="submit"
        className={btnClass}
        onClick={this.onClick}
        {...(this.props.aos ? '' : { 'data-aos': 'fade-in' })}
        value={this.props.children}
        disabled={disabled}
      />
    )
  }
}
