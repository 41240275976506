import React, { Component } from 'react'
import WithClickOutside from '../WithClickOutside'
import classnames from 'classnames'
import './Select.scss'
import './MultiSelect.scss'

export default class Select extends Component {
  state = {
    isOpen: false,
  }
  static defaultProps = {
    options: [],
  }
  onChange = value => () => {
    const { onChange } = this.props
    onChange && onChange(value)
    return this.setState({ isOpen: false })
  }
  onOpen = () => {
    this.setState({ isOpen: true })
  }
  onClose = () => {
    this.setState({ isOpen: false })
  }
  render() {
    const { isOpen } = this.state
    const {
      helper,
      value,
      options,
      icon,
      label,
      color,
      className,
      error,
      disabled,
    } = this.props
    const { isFocused } = this.state
    const rightIcon = isOpen ? 'sort-asc' : 'sort-desc'
    const classes = classnames(className, 'select__container', {
      'select__container-error': error,
    })
    const inputClasses = classnames({
      'select__input-value': value,
      'select__input-label': !value,
    })
    const helperClass = classnames({
      'helper--display': !disabled && !error && isFocused,
      'helper--default': !disabled && !error,
      'helper--error': error && !disabled,
      'helper--disabled': disabled,
    })
    return (
      <WithClickOutside onClick={this.onClose}>
        <div className={classes}>
          <div
            className={
              `select__input ` +
              (isOpen ? 'select__input-open ' : '') +
              (error ? 'select__input-error ' : '') +
              (value ? 'select__input-filled' : '')
            }
            onClick={isOpen ? this.onClose : this.onOpen}
            style={{ paddingLeft: icon || (color && value) ? '38px' : '12px' }}
          >
            {icon && (
              <i
                className={`icon left-icon icon-${icon}`}
                style={{ opacity: value ? '1' : '0.5' }}
              />
            )}
            {value && color && <span className={`icon color color-${color}`} />}
            <span className={inputClasses}>
              {(value && value.label) || label}{' '}
            </span>
            <i className={`icon icon-${rightIcon}`} />
          </div>
          <span className={helperClass}>{error || helper}</span>
          {isOpen && (
            <div className="select__dropdown__container">
              {options.map(option => (
                <div
                  className={
                    'select__dropdown__row ' +
                    (value && value.value === option.value
                      ? 'select__dropdown__row-active'
                      : '')
                  }
                  onClick={this.onChange(option)}
                >
                  {option.label}{' '}
                  {value && value.value === option.value ? (
                    <i class="icon icon-checkbox" />
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </WithClickOutside>
    )
  }
}
