import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './Checkbox.scss'

export default function Checkbox({
  checked,
  disabled,
  // Forwarded props.
  indeterminate,
  className,
  style,
  ...rest
}) {
  const iconName = indeterminate
    ? 'checkbox-indeterminate'
    : checked
    ? 'checkbox-checked'
    : null

  const wrapperClassName = classNames(className, 'Checkbox', {
    'Checkbox--checked': indeterminate || checked,
    'Checkbox--disabled': disabled,
  })

  return (
    <span className={wrapperClassName} style={style}>
      <input
        {...rest}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className="Checkbox__nativeInput"
      />

      {iconName != null && <i className={`icon icon-${iconName}`} />}
    </span>
  )
}

Checkbox.propTypes = {
  /**
   * Whether the checkbox is checked or not.
   */
  checked: PropTypes.bool.isRequired,

  /**
   * Whether the checkbox is indeterminate or not.
   */
  indeterminate: PropTypes.bool,

  /**
   * Whether the checkbox is disabled or not.
   */
  disabled: PropTypes.bool,
}

Checkbox.defaultProps = {
  indeterminate: false,
  disabled: false,
  onChange: () => {},
}
