import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import * as moment from 'moment'
import 'moment/locale/fr'

import Study from './Study'
import './Studies.scss'
import Button from '../../Button'

const MasonryBreakpoints = {
  default: 2,
  768: 1,
}

const urlFinder = /((https|http)?:\/\/)?(([\w\d]+)\.(com|org|net|us|uk|edu|ly)(\/[\w\d]+)?)/

export default class Studies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studies: null,
    }
  }

  componentDidMount() {
    fetch('/fb_posts.json')
      .then(response => response.json())
      .then(data => {
        const filteredPosts = data.filter(
          post => post.message.indexOf('[ETUDE]') !== -1,
        )
        this.setState({
          studies: filteredPosts,
        })
        console.log(filteredPosts)
      })
  }

  render() {
    return (
      <div>
        <section id="studies">
          <div className="grid-container">
            <h2 className="col-12">Découvrez nos derniers sondages</h2>
            <div className="col-7 content">
              <p>
                Pour chaque sondage, Radio Musique Test recherche les opinions
                spécifiques d'un groupe très précis de personnes en termes de
                sexe, d’âge, de localisation, de CSP, d’habitude d’écoute d’un
                média, de radio favorite pour la musique…
              </p>
              <p>
                Ainsi, au début de chaque sondage, nous vous poserons quelques
                questions afin de nous assurer qu’il est pertinent pour vous de
                participer. Si jamais tel n’était pas le cas, vous pouvez voir
                s'afficher un message indiquant que vous n'avez pas été
                sélectionné(e) pour ce sondage. Plus vous nous fournirez
                d'informations lors de votre première inscription, plus nous
                pourrons vous proposer des sondages adaptés à votre profil.
              </p>
              <p>
                Bien entendu, les informations communiquées resteront
                entièrement confidentielles et ne feront en aucun cas l’objet
                d’une cession à des sociétés tierces pour de la prospection
                commerciale.
              </p>
              <div className="btnGroup">
                <Link to="/signin">
                  <Button>Je m'inscris</Button>
                </Link>
                <a
                  href="https://www.facebook.com/RadioMusiqueTest"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="fb" icon="facebook">
                    Nous suivre
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="studies">
          <div className="grid-container">
            {this.state.studies ? (
              <h3 className="col-12">Derniers sondages en ligne</h3>
            ) : (
              <h3 className="col-12">
                Aucun sondage en cours, suivez nous sur facebook pour ne rater
                aucune étude
              </h3>
            )}
            {this.state.studies && (
              <Masonry
                breakpointCols={MasonryBreakpoints}
                className="studies_feed col-12"
                columnClassName="studies_column"
              >
                {this.state.studies.map((study, i) => {
                  const found = study.message.match(urlFinder)
                  let url
                  if (found.length === 7) {
                    url = `https://${found[3]}`
                  }
                  return (
                    <Study
                      key={i}
                      date={moment(study.created_time)
                        .locale('fr')
                        .format('LL')}
                      mediaType={'image'}
                      mediaLink={study.full_picture}
                      link={url}
                    >
                      {study.message}
                    </Study>
                  )
                })}
              </Masonry>
            )}

            <div className="col-12 btnGroup">
              <Link to="/signin">
                <Button>Je m'inscris</Button>
              </Link>
              <a
                href="https://www.facebook.com/RadioMusiqueTest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="fb" icon="facebook">
                  Nous suivre
                </Button>
              </a>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
