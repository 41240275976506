import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import throttle from 'lodash.throttle'
import { NavHashLink as NavLink } from 'react-router-hash-link'
import Button from '../../Button'
import './Header.scss'
import Logo from './logo.png'

smoothscroll.polyfill()

function isActive(state, start, end) {
  return (
    state.scroll > start * state.height &&
    state.scroll < end * state.height &&
    !state.isLegal
  )
}

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset
  window.scroll({
    top: elementPosition,
    behavior: 'smooth',
  })
}

export default class Header extends Component {
  state = {
    isTop: true,
    isToggle: false,
    isLegal:
      // window.location.pathname === '/rules' ||
      window.location.pathname === '/privacy-policy' ||
      window.location.pathname === '/legal' ||
      window.location.pathname === '/terms',
  }

  handleScroll = throttle(() => {
    this.setState({
      isTop: window.scrollY < 40,
      scroll: window.pageYOffset,
      height: window.innerHeight - 80,
      isLegal:
        // window.location.pathname === '/rules' ||
        window.location.pathname === '/privacy-policy' ||
        window.location.pathname === '/terms' ||
        window.location.pathname === '/legal',
    })
  }, 250)

  toggleMenu = () => {
    this.setState({ isToggle: !this.state.isToggle })
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    let menuClass = this.state.isToggle ? ' nav-open' : ''
    return (
      <div>
        <header
          className={!this.state.isTop || this.state.isLegal ? 'scrolled' : ''}
        >
          <div className="grid-container">
            <NavLink smooth to="/#home" scroll={el => scrollWithOffset(el, 60)}>
              <div className="title">
                <img className="logo" alt="logo" src={Logo} />
                <h1>Radio Musique Test</h1>
              </div>
            </NavLink>
            <nav className={`nav${menuClass}`}>
              <NavLink
                smooth
                to="/#about"
                scroll={el => scrollWithOffset(el, 60)}
                className="nav__item"
                activeClassName="nav__item-active"
                isActive={() => isActive(this.state, 1, 2)}
                onClick={this.toggleMenu}
              >
                Participation
              </NavLink>
              <NavLink
                smooth
                to="/#gains"
                scroll={el => scrollWithOffset(el, 60)}
                className="nav__item"
                activeClassName="nav__item-active"
                isActive={() => isActive(this.state, 2, 3)}
                onClick={this.toggleMenu}
              >
                Gains
              </NavLink>
              <NavLink
                smooth
                to="/#studies"
                scroll={el => scrollWithOffset(el, 60)}
                className="nav__item"
                activeClassName="nav__item-active"
                isActive={() => isActive(this.state, 3, 10)}
                onClick={this.toggleMenu}
              >
                Sondages
              </NavLink>
              {/* <NavLink
                to="/rules"
                className="nav__item"
                activeClassName="nav__item-active"
                onClick={this.toggleMenu}
              >
                Règlement
              </NavLink> */}
              <NavLink
                to="/contact"
                className="nav__item"
                activeClassName="nav__item-active"
                onClick={this.toggleMenu}
              >
                Contact
              </NavLink>
              <a
                className="nav__item"
                href="https://www.facebook.com/RadioMusiqueTest"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.toggleMenu}
              >
                <span className="icon icon-facebook-filled" />
                <span>Nous suivre</span>
              </a>
              <Link
                to="/signin"
                className="mobileSignup"
                onClick={this.toggleMenu}
              >
                <Button aos={'disabled'}>Je m'inscris</Button>
              </Link>
            </nav>
            <a
              onClick={this.toggleMenu}
              className="fbBtn"
              href="https://www.facebook.com/RadioMusiqueTest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon icon-facebook" />
            </a>
          </div>
          <Link to="/signin" className="signup">
            Je m'inscris
          </Link>
        </header>
        <div
          className={`btnMenu ${this.state.isToggle ? 'btnMenu__active' : ''}`}
          onClick={this.toggleMenu}
        >
          <div className="btnMenu__hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="btnMenu__cross">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    )
  }
}
